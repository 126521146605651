import React from "react"

export const TitleUnderlineTestimonyOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="137" height="10" viewBox="0 0 137 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.4092 8.72465C22.2418 7.97829 1.65098 10.1013 0.471203 9.99622C-0.778311 9.75296 0.73273 3.89821 1.8137 2.96941C2.47624 2.4055 2.42974 2.81461 8.14264 2.4055C8.14264 2.4055 16.7556 1.80842 27.3445 1.11182C27.3445 1.11182 44.3553 0.210661 67.7764 0.000575625C69.6187 -0.0160101 68.16 0.332289 70.3277 0.210661C74.948 -0.0381243 85.0023 0.431804 88.5009 0.232775C91.3079 0.072447 91.9124 0.0613902 94.5916 0.365461C96.4339 0.575547 111.893 1.09523 113.607 0.780103C114.026 0.702703 114.328 0.75246 114.322 0.879617C114.444 0.890674 124.574 1.64809 124.621 1.76419C124.69 1.89687 124.998 1.9798 125.295 1.91346C125.841 1.7863 136.796 2.17883 136.941 2.56583C137.36 3.67154 135.448 8.03911 134.105 9.05083C132.716 10.0957 125.981 8.26025 120.029 8.57538C92.2727 6.94445 92.4877 7.35909 87.85 7.57471C87.2107 7.17112 84.6884 8.03911 83.189 7.34804C82.5613 7.05502 78.0515 6.94445 77.0402 7.19324C76.8252 7.24852 75.2154 7.23746 72.8907 7.19876C71.7284 7.18218 70.3859 7.15453 68.9504 7.13242C67.0325 7.09925 52.5207 6.94998 50.7017 7.59129C45.8082 6.94998 30.0643 8.33765 25.4092 8.72465Z" fill="#FFBA00"/>
      </svg>
  </span>
)

export const TitleUnderlineTestimonyTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="207" height="10" viewBox="0 0 207 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.392 8.72465C33.6062 7.97829 2.49454 10.1013 0.711964 9.99622C-1.17599 9.75296 1.10712 3.89821 2.74042 2.96941C3.74147 2.4055 3.67122 2.81461 12.3031 2.4055C12.3031 2.4055 25.3168 1.80842 41.3161 1.11182C41.3161 1.11182 67.0186 0.210661 102.407 0.000575625C105.19 -0.0160101 102.986 0.332289 106.262 0.210661C113.243 -0.0381243 128.434 0.431804 133.72 0.232775C137.962 0.072447 138.875 0.0613902 142.923 0.365461C145.707 0.575547 169.065 1.09523 171.655 0.780103C172.287 0.702703 172.744 0.75246 172.735 0.879617C172.919 0.890674 188.225 1.64809 188.295 1.76419C188.401 1.89687 188.866 1.9798 189.314 1.91346C190.139 1.7863 206.692 2.17883 206.911 2.56583C207.544 3.67154 204.655 8.03911 202.626 9.05083C200.527 10.0957 190.35 8.26025 181.358 8.57538C139.419 6.94445 139.744 7.35909 132.737 7.57471C131.771 7.17112 127.96 8.03911 125.694 7.34804C124.746 7.05502 117.932 6.94445 116.404 7.19324C116.079 7.24852 113.647 7.23746 110.134 7.19876C108.378 7.18218 106.349 7.15453 104.18 7.13242C101.283 7.09925 79.3561 6.94998 76.6076 7.59129C69.2139 6.94998 45.4257 8.33765 38.392 8.72465Z" fill="#FFBA00"/>
      </svg>
  </span>
)