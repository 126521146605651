import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="144" height="8" viewBox="0 0 144 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.7718 8C23.7947 7.25735 1.99541 8.08646 0.410905 7.86265C-0.882693 7.56254 1.18459 2.27751 2.38535 1.49925C2.80623 1.21949 3.2952 1.17881 4.41549 1.30597C6.19187 1.54505 29.161 1.07198 29.161 1.07198C29.4643 1.23475 30.3741 0.685396 30.5412 0.883775C31.0673 1.34666 68.297 0.715913 71.4722 0.69048C73.3971 0.67522 71.8745 0.99059 74.1398 0.863424C78.9676 0.609092 89.4897 0.853252 93.1477 0.563314C96.0753 0.324241 96.7066 0.314075 99.5167 0.507368C102.389 0.680314 117.658 0.431066 119.403 0.0597408C119.83 -0.0419921 120.159 -0.00638251 120.159 0.11061C120.159 0.136044 130.922 0.222513 130.972 0.314073C131.052 0.431066 131.38 0.487015 131.683 0.405629C132.247 0.283549 143.703 -0.15899 143.883 0.181815C144.446 1.15845 142.868 5.31932 141.537 6.33157C140.175 7.41502 132.698 6.19932 126.688 6.85041L98.7058 6.91654L92.7082 7.32347C92.0212 6.97249 89.3783 7.85756 87.7814 7.22682C87.1068 6.97757 82.3409 6.94196 81.2887 7.20647C80.3726 7.40994 55.9923 6.89618 53.4918 7.60323C49.0354 7.01318 30.9621 7.85757 26.7718 8Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="178" height="8" viewBox="0 0 178 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.093 8C29.4129 7.25735 2.46655 8.08646 0.507925 7.86265C-1.09111 7.56254 1.46428 2.27751 2.94855 1.49925C3.46881 1.21949 4.07323 1.17881 5.45804 1.30597C7.65384 1.54505 36.0462 1.07198 36.0462 1.07198C36.4211 1.23475 37.5458 0.685396 37.7524 0.883775C38.4027 1.34666 84.4227 0.715913 88.3476 0.69048C90.727 0.67522 88.8449 0.99059 91.6451 0.863424C97.6128 0.609092 110.619 0.853252 115.141 0.563314C118.76 0.324241 119.54 0.314075 123.014 0.507368C126.564 0.680314 145.438 0.431066 147.596 0.0597408C148.124 -0.0419921 148.529 -0.00638251 148.529 0.11061C148.529 0.136044 161.834 0.222513 161.895 0.314073C161.995 0.431066 162.4 0.487015 162.775 0.405629C163.471 0.283549 177.633 -0.15899 177.855 0.181815C178.551 1.15845 176.6 5.31932 174.955 6.33157C173.272 7.41502 164.03 6.19932 156.601 6.85041L122.011 6.91654L114.598 7.32347C113.748 6.97249 110.482 7.85756 108.508 7.22682C107.674 6.97757 101.782 6.94196 100.482 7.20647C99.3495 7.40994 69.2127 6.89618 66.1218 7.60323C60.6132 7.01318 38.2726 7.85757 33.093 8Z" fill="#FFA700"/>
      </svg>
  </span>
)