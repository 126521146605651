import React from "react"
import TemplatePageWebchat from "@components/pageChannels/pageWebchat/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Implementa chat en vivo para tu web con chatbots con IA  [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/chat-en-vivo-web/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo integrar chatbots con IA en tu web para ofrecer chat en vivo y mejorar la atención al cliente. Optimiza tu servicio con tecnología avanzada."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Implementa chat en vivo para tu web con chatbots con IA "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/chat-en-vivo-web/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre cómo integrar chatbots con IA en tu web para ofrecer chat en vivo y mejorar la atención al cliente. Optimiza tu servicio con tecnología avanzada."
      />
    </Helmet>
    <TemplatePageWebchat location={location} />
  </div>
)

export default IndexPage
